.app-wrapper {
    min-height: 100vh;
    background: linear-gradient(to bottom, #000c02 0%, #002109 100%);
    color: white;
    position: relative;
    overflow: hidden;
  }
  
  .app-wrapper::after {
    content: '';
    position: absolute;
    top: 30%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    width: 800px;
    height: 800px;
    background: radial-gradient(circle, rgba(0,149,255,0.1) 0%, rgba(0,149,255,0) 70%);
    z-index: 1;
    pointer-events: none;
  }
  
.zebu-home-image{
  width: 60rem;
}
.subscribe-btn:hover{
    background: #fffafb !important;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255,51,102,0.4);
    color: #000510;
}

.dots {
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
  }
  
  .dot {
    width: 18px;
    height: 18px;
    background: rgba(255,255,255,0.3);
    border-radius: 0;
    display: inline-block;
    animation: pulse 1.5s ease-in-out infinite;
    opacity: 0.5;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  .dot:nth-child(4) {
    animation-delay: 0.9s;
  }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
      opacity: 0.5;
      background: rgba(255,255,255,0.3);
    }
    50% {
      transform: scale(1.2);
      opacity: 1;
      background: #fff;
    }
  }
  
  /* .custom-navbar {
    background: transparent;
    padding: 1rem 0;
  } */

  .coming-soon {
    font-size: 4rem;
    font-weight: bold;
    color: transparent;
    background: linear-gradient(to right,
      #001b0c 0%, #001b0c 25%, #ffffff 50%, #001b0c 75%, #001b0c 100%);
    background-size: 200% auto;
    background-clip: text;
    -webkit-background-clip: text;
    animation: cosmic-speed 3s linear infinite;
    letter-spacing: 1.5rem !important; 
  }
  
  @keyframes cosmic-speed {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }

.subscribe-btn{
    background: rgba(0, 149, 255, 0.1);
   
}
  .countdown-box {
    background: rgba(0, 149, 255, 0.1);
    border-radius: .5rem;
    padding: 1rem;
    backdrop-filter: blur(5px);
    min-width: 7rem;
  }
  
  .countdown-box .number {
    font-size: 2.5rem;
    font-weight: 700;
    color: #fff;
    text-shadow: 0 0 10px rgba(0,149,255,0.5);
  }
  
  .countdown-box .label {
    font-size: 0.8rem;
    letter-spacing: 0.1em;
    color: rgba(255,255,255,0.8);
  }

  .form-control-custom::placeholder {
    color: rgba(255, 255, 255, 0.7) !important;
    opacity: 1 !important;
  }

  @media (max-width: 425px) {
    .nav-bar-logo {
      max-width: 6rem !important;   
      max-height: 2.5rem !important;
    }

    .work-header,.our-work-intro{
      font-size: 1rem !important;
    }
    .form-head{
      font-size: 1.3rem !important;
    }
    .address-body{
      font-size: .8rem !important;
    }
    .member-name{
      font-size: 1rem !important;
    }
    .member-description{
      font-size: .7rem !important;
    }
    .member-role{
      font-size: .8rem !important;
    }

  }
/* Responsive iframe for large screens */
@media screen and (min-width: 2560px) {
  .iframe-col {
    width: 100%; 
    margin: 0 auto;
  }
  
  .iframe-container {
    max-width: 1600px; 
    margin: 0 auto;
  }
  
  .iframe-container iframe {
    height: 45rem; 
  }


  .iframe-col p {
    font-size: 2rem !important; 
  }
  
  .iframe-col h3 {
    font-size: 2.5rem !important; 
    line-height: 1.4;
  }


}


@media screen and (min-width: 3840px) {
  .iframe-container iframe {
    height: 1200px ; 
  }

  .iframe-col p {
    font-size: 2.5rem !important;
  }
  
  .iframe-col h3 {
    font-size: 3rem !important;
  }

}